jQuery(document).ready(function ($) {

    $('.job_filters select').selectric({
        openOnFocus: false,
        disableOnMobile: false,
        nativeOnMobile: false
    });

    $("a[href='#']").attr("href", "javascript:void(0)");

    jQuery(".job_filters .search_jobs select option").each(function () {
        var text1 = this.innerText;
        text1 = text1.replace(/--/g, " ");
        text1 = text1.replace(/_/g, " ");
        text1 = text1.replace(/-/g, " ");
        this.innerText = text1.trim();
    })

    jQuery(".job_filters .search_jobs .selectric-wrapper select").selectric("refresh");

    $(window).scroll(function () {
        var sticky = $('.site-header'),
            scroll = $(window).scrollTop();

        if (scroll >= 300) sticky.addClass('sticky');
        else sticky.removeClass('sticky');

    });

    $(".site-header-search-toggle-open").click(function () {
        $('.site-header').addClass("search_active");
        $('body').addClass("toggle_search_active");
        if ($('body').hasClass("stop_scroll")) {
            $('body').removeClass('stop_scroll');
            $('.site-header').removeClass('megaopen');
            $("#mega-menu-wrap-primary #mega-menu-primary > li.mega-menu-item.mega-menu-item-has-children").removeClass('active');
            $("#mega-menu-wrap-primary #mega-menu-primary > li.mega-menu-item.mega-menu-item-has-children > .mega-sub-menu").removeClass('show_submenu');
        }
    });

    $(".site-header-search-toggle-close").click(function () {
        $('.site-header').removeClass("search_active");
        $('body').removeClass("toggle_search_active");
    });

    $(".site-navigation-toggle-open").click(function () {
        $('.site-header').addClass("menu_active");
        $('body').addClass("toggle_menu_active");
        if ($('body').hasClass("stop_scroll")) {
            $('body').removeClass('stop_scroll');
            $('.site-header').removeClass('megaopen');
            $("#mega-menu-wrap-primary #mega-menu-primary > li.mega-menu-item.mega-menu-item-has-children").removeClass('active');
            $("#mega-menu-wrap-primary #mega-menu-primary > li.mega-menu-item.mega-menu-item-has-children > .mega-sub-menu").removeClass('show_submenu');
        }
    });

    $(".site-navigation-toggle-close").click(function () {
        $('.site-header').removeClass("menu_active");
        $('body').removeClass("toggle_menu_active");
        if ($('nav.secondary-navigation li.menu-item-has-children').hasClass("open")) {
            $('nav.secondary-navigation li.menu-item-has-children button.submenu-toggle').removeClass("active");
            $('nav.secondary-navigation li.menu-item-has-children').removeClass("open");
            $('nav.secondary-navigation li.menu-item-has-children .sub-menu').slideUp();
        }
    });

    $("body").on("click", "#mega-menu-wrap-primary #mega-menu-primary > li.mega-menu-item.mega-menu-item-has-children > a", function (event) {
        event.preventDefault();
        var self = $(this);
        $(this).parent().siblings().removeClass('active');
        $(this).parent().siblings().find('.mega-sub-menu ').removeClass('show_submenu');
        $(this).parent().toggleClass('active');
        $(this).next().toggleClass('show_submenu');

        if ($(this).parent().hasClass('active')) {
            $('body').addClass('stop_scroll');
            $('.site-header').addClass('megaopen');
            // setTimeout(function() {
            $(event.target).closest(".mega-menu-item-has-children").addClass("menu_enabled");
            // }, 500);
        } else {
            $('body').removeClass('stop_scroll');
            $('.site-header').removeClass('megaopen');
            $(event.target).closest(".mega-menu-item-has-children").removeClass("menu_enabled");
        }
    });

    $(document).on("click", function (e) {
        if ($('body').hasClass('stop_scroll') && $(e.target).closest(".mega-menu-item-has-children.menu_enabled").length == 0) {
            $('body').removeClass('stop_scroll');
            $('.site-header').removeClass('megaopen');
            $("#mega-menu-wrap-primary #mega-menu-primary > li.mega-menu-item.mega-menu-item-has-children").removeClass("menu_enabled active");
        }
    })

    $('nav.secondary-navigation').find('li.menu-item-has-children > a').after('<button class="submenu-toggle"><svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg"><title>Dropdown Arrow</title><path d="M1 1.5L5 5.5L9 1.5" stroke="#fff" stroke-width="2"></path></svg></button>');
    $('body').on('click', 'nav.secondary-navigation button.submenu-toggle', function () {
        $(this).parent().siblings().find('button.submenu-toggle').removeClass("active");
        $(this).parent().siblings().find('button.submenu-toggle').parent().find('> .sub-menu').slideUp().parent().removeClass('open');
        $(this).toggleClass('active').parent().find('> .sub-menu').slideToggle().parent().toggleClass('open');
    });

    if (jQuery(".accordion_list_section .accordion_list_section_inner .accordion_item .accordion_item_heading").length > 0) {
        jQuery(".accordion_list_section .accordion_list_section_inner .accordion_item .accordion_item_heading").on("click", function () {
            if (jQuery(this).next().is(":hidden")) {
                jQuery(".accordion_list_section .accordion_list_section_inner .accordion_item").removeClass("active");
                jQuery(".accordion_list_section .accordion_list_section_inner .accordion_item .accordion_item_content").slideUp();
                jQuery(this).parent().addClass("active");
                jQuery(this).next().slideDown();
            } else {
                jQuery(this).parent().removeClass("active");
                jQuery(this).next().slideUp();
            }
        })
    }

    jQuery(".team_member_list_section_grid_item_brief_link").on("click", function () {
        setTimeout(() => {
            if (jQuery(".lity").length > 0) {
                jQuery(".lity").addClass("team_member_popup");
            }
        });
    })

    $(".image_content_box_scroll__inner.slider").each(function (index) {
        if (jQuery(this).find(">div").length > 0) {
            jQuery(this).slick({
                infinite: false,
                variableWidth: true,
                slidesToShow: 2,
                slidesToScroll: 1,
                arrows: true,
                nextArrow: jQuery(".image_content_box_scroll .slick_arrows .next_arrow"),
                prevArrow: jQuery(".image_content_box_scroll .slick_arrows .prev_arrow"),
                dots: false,
                autoplay: false,
                autoplaySpeed: 1000,
                speed: 1000,
                responsive: [{
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 1
                    }
                }]
            });
        }
    });

    jQuery('.testimonial_slider').slick({
        infinite: true,
        variableWidth: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: false,
        autoplay: false,
        autoplaySpeed: 0,
        speed: 1000,
    });

    if (jQuery(".rest_location_list_slider").length > 0) {
        jQuery('.rest_location_list_slider').slick({
            infinite: false,
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: true,
            dots: true,
            autoplay: false,
            autoplaySpeed: 2000,
            speed: 1000,
            variableWidth: false,
            nextArrow: jQuery(".location_filter_with_controls .location_next_arrow"),
            prevArrow: jQuery(".location_filter_with_controls .location_prev_arrow"),
            responsive: [{
                breakpoint: 992,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1
                }
            }
            ]
        });
    }
    if (jQuery(".three_column_content_box_list_section_list.slider").length > 0) {
        jQuery(".three_column_content_box_list_section_list.slider").each(function () {
            jQuery(this).slick({
                infinite: false,
                slidesToShow: 4,
                slidesToScroll: 1,
                arrows: true,
                nextArrow: jQuery(this).closest(".three_column_content_box_list_section ").find(".slick_arrows .next_arrow"),
                prevArrow: jQuery(this).closest(".three_column_content_box_list_section ").find(".slick_arrows .prev_arrow"),
                dots: false,
                autoplay: false,
                autoplaySpeed: 2000,
                speed: 1000,
                variableWidth: true,
                responsive: [{
                    breakpoint: 1481,
                    settings: {
                        slidesToShow: 3
                    }
                }, {
                    breakpoint: 1441,
                    settings: {
                        slidesToShow: 4
                    }
                }, {
                    breakpoint: 1281,
                    settings: {
                        slidesToShow: 3
                    }
                }, {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 2,
                        infinite: true,
                    }
                }
                ]
            });
        })
    }
    $('.location_filter .location_filter_button').on('click', function () {
        var filterClass = $(this).attr('data-value');
        $('.location_filter .location_filter_button').removeClass("active");
        $(this).addClass("active");
        if (filterClass != "*") {
            $('.rest_location_list_slider').slick('slickUnfilter');
            $('.rest_location_list_slider').slick('slickFilter', "." + filterClass);
        } else {
            $('.rest_location_list_slider').slick('slickUnfilter');
        }
    });

    offsetLeft();

    equalheight('.location_list_section .rest_location_list_slider_item .location_logo');

    equalheight('.location_list_section .rest_location_list_slider_item .address_with_google_map');

    if (jQuery("#number_with_content_slider_4").length > 0) {
        if (jQuery("#number_with_content_slider_4").offset().top <= jQuery(window).scrollTop()) {
            jQuery("#number_with_content_slider_4 .background_video").addClass("move_up");
        } else {
            jQuery("#number_with_content_slider_4 .background_video").removeClass("move_up");
        }
    }

    jQuery(".hero_banner .content_inner .play_icon, .team_member_list_section_grid_item_brief_link").on("click", function () {
        setTimeout(() => {
            jQuery(".lity").addClass("reveal_popup");
        });
    })

    jQuery(document).on("click", function (e) {
        if (jQuery(".reveal_popup").length > 0 && jQuery(e.target).closest(".team_member_list_section_grid_item_popup").length == 0) {
            jQuery(".lity").removeClass("reveal_popup");
            setTimeout(() => {
                jQuery("[data-lity-close]").click();
            }, 500);
        }
    })


    var prevScrollpos = 0; //jQuery('#masthead').height() * 0.5;
    window.onscroll = function () {
        var currentScrollPos = window.pageYOffset,
            siteheader = document.getElementById("masthead"),
            siteheaderHeigjt = siteheader.offsetHeight * 0.5;
        if (prevScrollpos > currentScrollPos) {
            if (jQuery(".admin-bar").length > 0) {
                siteheader.style.top = "32px";
            } else {
                siteheader.style.top = "0";
            }
        } else {
            if (siteheaderHeigjt < currentScrollPos) {
                siteheader.style.top = "-" + (siteheaderHeigjt * 4) + "px";
            }
        }
        prevScrollpos = currentScrollPos;
    }

    homeScroll();

    jQuery(document).on("change", ".enquiry_locations select", function () {
        var thisEmail = jQuery(this).val();
        jQuery(".dynamic_admin_email input").val(thisEmail);
    })

    jQuery(document).on('gform_page_loaded', function (event, form_id, current_page) {
        jQuery(document).on("change", ".enquiry_locations select", function () {
            var thisEmail = jQuery(this).val();
            jQuery(".dynamic_admin_email input").val(thisEmail);
        })
    });

    jQuery(".expandable_content_sec .section_content").each(function () {
        jQuery(this).removeClass("read_less").removeAttr("style");
        var height1 = jQuery(this).outerHeight();
        jQuery(this).css("height", height1 + "px");
        jQuery(this).addClass("read_less");
    })

    jQuery(".expandable_content_sec .expandable_content_sec_inner .text_link").on("click", function () {
        if (jQuery(this).parent().find(".section_content").hasClass("read_less")) {
            jQuery(this).parent().find(".section_content").removeClass("read_less");
            jQuery(this).text("Read less");
        } else {
            jQuery(this).parent().find(".section_content").addClass("read_less");
            jQuery(this).text("Read more");
        }
    })

    $(".site-header .site-nav .site-nav-overlay").on("click", function () {
        $('.site-header').removeClass("menu_active");
        $('body').removeClass("toggle_menu_active");
        if ($('nav.secondary-navigation li.menu-item-has-children').hasClass("open")) {
            $('nav.secondary-navigation li.menu-item-has-children button.submenu-toggle').removeClass("active");
            $('nav.secondary-navigation li.menu-item-has-children').removeClass("open");
            $('nav.secondary-navigation li.menu-item-has-children .sub-menu').slideUp();
        }
    });

    $(".left_right_image_content_section_main .left_right_image_content_section_inner_media .media_image.media_video_popup .play_video_in_popup").on("click", function () {
        setTimeout(() => {
            jQuery("div.lity").addClass("lity_left_right_image_video_popup");
            $(".lity-close").appendTo(".lity-content");
        }, 100);
    })
});

function offsetLeft() {
    if (jQuery('.three_column_content_box_list_section_list.slider').length > 0) {
        var pastoffset = jQuery('.three_column_content_box_list_section_list.slider').offset(),
            pastwindWidth = jQuery(window).width();
        jQuery('.three_column_content_box_list_section_list.slider').css('width', (pastwindWidth - pastoffset.left));
    }
    if (jQuery('.number_content_inner_slider').length > 0) {
        var pastoffset = jQuery('.number_content_inner_slider').offset(),
            pastwindWidth = jQuery(window).width();
        jQuery('.number_content_inner_slider').css('width', (pastwindWidth - pastoffset.left));
    }
    if (jQuery('.image_content_box_scroll__inner.slider').length > 0) {
        var pastoffset = jQuery('.image_content_box_scroll__inner.slider').offset(),
            pastwindWidth = jQuery(window).width();
        jQuery('.image_content_box_scroll__inner.slider').css('width', (pastwindWidth - pastoffset.left));
    }
}

jQuery(window).resize(function () {
    equalheight('.location_list_section .rest_location_list_slider_item .location_logo');
    equalheight('.location_list_section .rest_location_list_slider_item .address_with_google_map');
});

jQuery(window).on("scroll", function () {
    if (jQuery("#number_with_content_slider_4").length > 0) {
        if (jQuery("#number_with_content_slider_4").offset().top <= jQuery(window).scrollTop()) {
            jQuery("#number_with_content_slider_4 .background_video").addClass("move_up");
        } else {
            jQuery("#number_with_content_slider_4 .background_video").removeClass("move_up");
        }
    }

    if (jQuery(".inner_banner__wrapper .image").length > 0) {
        // jQuery(".inner_banner__wrapper .image").css("transform", "translateY(" + (jQuery(window).scrollTop() * -0.25) + "px)")
        jQuery(".inner_banner__wrapper .image").css("margin-top", (jQuery(window).scrollTop() * -0.25) + "px");
    }

    if (jQuery(".inner_banner__wrapper > .content").length > 0) {
        jQuery(".inner_banner__wrapper > .content").css("transform", "translateY(" + (jQuery(window).scrollTop() * -0.45) + "px)")
    }

    if (jQuery(".hero_banner .content").length > 0) {
        jQuery(".hero_banner .content").css("transform", "translateY(" + (jQuery(window).scrollTop() * -0.45) + "px)")
    }

    if (jQuery(".hero_banner .image").length > 0) {
        jQuery(".hero_banner .image").css("transform", "translateY(" + (jQuery(window).scrollTop() * -0.25) + "px)")
    }

    homeScroll();
})

function equalheight(container) {
    var currentTallest = 0,
        currentRowStart = 0,
        rowDivs = new Array(),
        $el,
        topPosition = 0;
    jQuery(container).each(function () {

        $el = jQuery(this);
        jQuery($el).height('auto')
        topPostion = $el.position().top;

        if (currentRowStart != topPostion) {
            for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
                rowDivs[currentDiv].height(currentTallest);
            }
            rowDivs.length = 0; // empty the array
            currentRowStart = topPostion;
            currentTallest = $el.height();
            rowDivs.push($el);
        } else {
            rowDivs.push($el);
            currentTallest = (currentTallest < $el.height()) ? ($el.height()) : (currentTallest);
        }
        for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
            rowDivs[currentDiv].height(currentTallest);
        }
    });
}

function homeScroll() {
    if (jQuery(window).width() > 767 && jQuery(window).scrollTop() > 200 && jQuery("body.home").length > 0 && jQuery(".site-main > .section_2").length > 0 && !jQuery(".site-main > .section_2").hasClass("moved")) {
        jQuery("body,html").animate({
            scrollTop: jQuery(".site-main > .section_2").offset().top - 100
        }, 1000);
        jQuery(".site-main > .section_2").addClass("moved");
    }

    if (jQuery(window).scrollTop() < 10) {
        jQuery(".site-main > .section_2").removeClass("moved");
    }
}

// function homeScroll() {
//     if (jQuery(window).width() > 767 && jQuery(window).scrollTop() > 200 && jQuery("body.home").length > 0 && jQuery(".section_image_content_box_scroll").length > 0 && !jQuery(".section_image_content_box_scroll").hasClass("moved")) {
//         jQuery("body,html").animate({
//             scrollTop: jQuery(".section_image_content_box_scroll").offset().top - 100
//         }, 1000);
//         jQuery(".section_image_content_box_scroll").addClass("moved");
//     }

//     if (jQuery(window).scrollTop() < 10) {
//         jQuery(".section_image_content_box_scroll").removeClass("moved");
//     }
// }